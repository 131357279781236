import { defineComponent, Ref, ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';
import store from '@/store';

interface ShortvideoHotData {
    onlyid: string | number;
    title: string;
    backImg: string;
}

export default defineComponent({
    components: { FetchData },
    setup() {
        const shortvideoHotData: Ref<ShortvideoHotData[]> = ref([
            {
                onlyid: 'aaaaa',
                title: '标题占位符标题占位符',
                backImg: store.state.limitImgUrl || '',
            },
            {
                onlyid: 'bbbbbb',
                title: '标题占位符标题占位',
                backImg: store.state.limitImgUrl || '',
            },
            {
                onlyid: 'cccccc',
                title: '标题占位符标题占',
                backImg: store.state.limitImgUrl || '',
            },
            {
                onlyid: 'dddddd',
                title: '标题占位符标题占位符',
                backImg: store.state.limitImgUrl || '',
            },
        ]);
        const touchData = (data: ShortvideoHotData[]) =>
            (shortvideoHotData.value = data);
        return { shortvideoHotData, touchData };
    },
});
