var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-album-container"},[(false)?_c('div',{staticClass:"toolbar"},[_vm._m(0)]):_vm._e(),(_vm.$route.name === 'PhotoAlbum')?_c('skeleton',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{staticClass:"album-area"},[_vm._l((_vm.photoData),function(item){return _c('div',{key:item.onlyid,staticClass:"album-item",style:({
                    backgroundImage: ("url(" + (Array.isArray(item.coverImg)
                            ? item.coverImg[0]
                            : item.coverImg) + ")"),
                })},[_c('div',{staticClass:"album-item-imgarea"},[_c('router-link',{attrs:{"to":_vm.isLoading ? '' : ("/photo/album/" + (item.onlyid))}},[_c('img',{staticClass:"album-item-img",attrs:{"src":Array.isArray(item.coverImg)
                                    ? item.coverImg[0]
                                    : item.coverImg,"data-image-object-fit-compat-ie":"","alt":item.title}})])],1),_c('div',{staticClass:"album-item-textarea"},[_c('h4',[_c('router-link',{attrs:{"to":_vm.isLoading
                                    ? ''
                                    : ("/photo/album/" + (item.onlyid))}},[_vm._v(" "+_vm._s(item.title))])],1),_c('p',[_c('span',[_vm._v(_vm._s(item.description))])])])])}),_vm._l(([, ,]),function(item,index){return _c('i',{key:index})})],2)]):_vm._e(),(_vm.$route.name === 'PhotoAlbumDetail')?_c('album-detail'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"tab-list"},[_c('li',{staticClass:"tab-item"},[_vm._v("相册")]),_c('li',{staticClass:"tab-item"},[_vm._v("时间线")]),_c('li',{staticClass:"tab-item"},[_vm._v("短视频")])])}]

export { render, staticRenderFns }