import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';

import PageNodeMain from '@/components/PageNodeMain/index.vue';
import PageNodeSub from '@/components/PageNodeSub/index.vue';

import PhotoAlbum from '@/components/Article/PhotoAlbum';
import PhotoHot from '@/components/Article/PhotoHot';
import ShortvideoHot from '@/components/Article/ShortvideoHot';

import { Tab, Empty } from '@/components/UI';

export default defineComponent({
	components: {
		Card,
		PageNodeMain,
		PageNodeSub,
		PhotoAlbum,
		PhotoHot,
		ShortvideoHot,
		Tab,
		Empty,
	},
	setup(props: any, { root }: any) {
		const { $router, $route } = root;
		const t = root.$t.bind(root);
		const pushroute = (path: string) =>
			$router
				.push({
					path,
				})
				.catch(() => {
					/**/
				});
		const navigation: Ref<any[]> = ref([
			{
				key: 'album',
				title: '相册列表',
				action: () => pushroute('/photo'),
				isActive: true,
			},
			{
				key: 'timeline',
				title: '时间线',
				action: () => pushroute('/photo/timeline'),
				isActive: false,
			},
			{
				key: 'shortvideo',
				title: '短视频',
				action: () => pushroute('/photo/shortvideo'),
				isActive: false,
			},
		]);
		//console.log($route);
		const navigationDefaultKey = (() => {
			const sMat = '' + $route?.matched[$route.matched.length - 1]?.path;
			const area = sMat.substring(sMat.lastIndexOf('/') + 1);
			//console.log(navigation.value.find(item => item.key === area)?.key);
			return navigation.value.find(item => item.key === area)?.key;
		})();
		const time = new Date().getTime();
		return { time, navigation, navigationDefaultKey, t };
	},
});
