import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { req } from '@/api';

import { Skeleton } from '@/components/UI';
import store from '@/store';

import AlbumDetail from './album-detail';

export default defineComponent({
    name: 'PhotoAlbum',
    components: { Skeleton, AlbumDetail },
    setup(props: any, { root }: any) {
        const { $message, $route } = root;
        const isLoading: Ref<boolean> = ref(true);
        const isError: Ref<boolean> = ref(false);
        const photoData: Ref<any[]> = ref([
            {
                onlyid: 'adwqui7832',
                coverImg: store.state.limitImgUrl,
                title: '记录我们的曾经',
                description: '我们曾经的点点滴滴',
                type: '记事',
                time: 1675178511000,
                isPrivate: false, //
            },
            {
                onlyid: 'adwqui7832123523',
                coverImg: store.state.limitImgUrl,
                title: '太行山风光',
                description: '感受太行山的魅力',
                type: '风景',
                time: 1675178511000,
                isPrivate: false, //
            },
        ]);
        req('PHOTO_PHOTO_ALBUM_ALL', {})
            .then((data: any) => {
                photoData.value = [...data];
            })
            .catch(error => {
                $message.error(error);
                isError.value = true;
            })
            .finally(() => {
                isLoading.value = false;
            });
        return { photoData, isLoading, isError };
    },
});
