import store from '@/store';
import { defineComponent, ref, Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

interface PhotoHotData {
    onlyid: string | number;
    title: string;
    description: string;
    imgUrl: string;
    time?: number;
}

export default defineComponent({
    components: { FetchData },
    setup() {
        const photoHotData: Ref<PhotoHotData[]> = ref([
            {
                onlyid: 'aaaaaaa',
                title: '标题占位符',
                description: '简介占位符',
                imgUrl: store.state.limitImgUrl || '',
            },
            {
                onlyid: 'bbbbbb',
                title: '标题占位符12345',
                description: '简介占位符',
                imgUrl: store.state.limitImgUrl || '',
            },
            {
                onlyid: 'ccccccc',
                title: '标题占位符123',
                description: '简介占位符1111',
                imgUrl: store.state.limitImgUrl || '',
            },
        ]);
        const touchData = (data: PhotoHotData[]) => (photoHotData.value = data);
        return { photoHotData, touchData };
    },
});
