












































import { req } from '@/api';
import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api';
import { Skeleton, Empty } from '@/components/UI';
import store from '@/store';

interface AlbumInfo {
	title: string;
	description: string;
	count: number;
	isPrivate?: boolean;
	coverImg: string;
}

export default defineComponent({
	components: { Skeleton, Empty },
	setup(props: any, { root }: any) {
		const { $message, $route, $previewRefresh, $preview } = root;
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const errorText: Ref<string> = ref('');
		const keyForPreview: Ref<string | number> = ref(Math.random());
		const albumInfo: Ref<AlbumInfo> = ref({
			title: '未知相册名',
			description: '相册描述',
			count: 0, //多少张照片
			coverImg: store.state.limitImgUrl, //'/static/testimg/photo/uu7.jfif',
		});
		const photoData: Ref<any[]> = ref([
			{
				onlyid: '1',
				title: '这是第一张图片',
				imgUrl: store.state.limitImgUrl || '/static/testimg/b1.jpg',
			},
			{
				onlyid: '2',
				title: '这是第二张图片',
				imgUrl: store.state.limitImgUrl || '/static/testimg/b2.jpg',
			},
		]);
		req('PHOTO_PHOTO_ALBUM', {
			onlyid: $route?.params?.onlyid,
		})
			.then((data: any) => {
				if (data.info && data.data) {
					albumInfo.value = data.info;
					photoData.value = data.data;
				} else {
					throw '数据格式不符合规范,请后端程序员重新调试';
				}
			})
			.catch(error => {
				$message.error(error);
				isError.value = true;
				errorText.value = '' + error;
			})
			.finally(() => {
				isLoading.value = false;
				keyForPreview.value = Math.random();
				$previewRefresh();
			});
		onMounted(() => {
			$preview.on('close', () => {
				//console.log('关闭回调');
			});
		});
		return { albumInfo, photoData, isLoading, isError, errorText, keyForPreview };
	},
});
